<template>
  <div class="page-wrap">
    <div class="login-warp">
      <div class="flex items-center mb-4 px-3.5 py-2.5 rounded-lg bg-gray-100">
        <img :src="require('assets/login/user.png')" alt="" class="icon-xs">
        <input v-model="username" type="text" class="flex-1 text-sm ml-3 bg-gray-100" placeholder="用户名/账号/手机号">
      </div>
      <div class="flex items-center mb-4 px-3.5 py-2.5 rounded-lg bg-gray-100">
        <img :src="require('assets/login/password.png')" alt="" class="icon-xs">
        <input v-model="password" type="password" class="flex-1 text-sm ml-3 bg-gray-100" placeholder="请输入您的密码">
      </div>
      <div class="flex items-center justify-between">
        <van-checkbox v-model="checked" icon-size="16" shape="square">记住密码</van-checkbox>
      </div>
      <div class="submit-btn mt-7 rounded-full" @click="Click">登录</div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import { login } from '../api'
import { Checkbox } from 'vant'
export default {
  components:{
    [Checkbox.name]:Checkbox,
  },
  props:{},
  data() {
    return {
      username: '',
      password: '',
      checked: false
    }
  },
  computed:{},
  watch:{},
  created() {
    // this.login()
    if (localStorage.getItem('account')) {
      var account = JSON.parse(localStorage.getItem('account'))
      this.username = account.username
      this.password = account.password
      this.checked = true
    }
  },
  mounted() {},
  methods:{
    Click() {
      if (this.username=='') {
        return this.$toast('请输入用户名/账号/手机号')
      }
      if (this.password=='') {
        return this.$toast('请输入您的密码')
      }
      this.login()
    },
    login() {
      this.$toast.loading()
      if (this.checked) {
        var account = {
          username: this.username,
          password: this.password
        }
        localStorage.setItem('account', JSON.stringify(account))
      } else {
        localStorage.removeItem('account')
      }
      login({
        username:this.username,
        password:md5(this.password)
      }).then(res => {
        var data = res.data.data
        var token = data.tokenHead + ' ' + data.token
        localStorage.setItem('token', token)
        localStorage.setItem('tokenHeader', data.tokenHeader)
        localStorage.setItem('userInfo', JSON.stringify(data))
        localStorage.setItem('zdmj',JSON.stringify({
          Authorization: token
        }))
        this.$router.replace({ path:'/home' })
      }).finally(()=>{
        this.$toast.clear()
      })
    }
  }
}
</script>
<style scoped>
.page-wrap>>>.van-checkbox__label{
  font-size: 14px;
}
.page-wrap{
  position:relative;
  padding-top:150px;
}
.page-wrap:before{
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-image:url('../assets/login/bg.jpg');
  background-repeat:no-repeat;
  background-position:top;
  background-size:100% auto;
}
.login-warp{
  position:relative;
  z-index:1;
  margin:0 30px;
  padding:40px 20px;
  background-color:#fff;
  border-radius:14px;
}
.pwd{
  font-size: 0.875rem;
  color: #333;
}
</style>
